<template>
  <div class="detail">
    <van-form @submit="onSubmit" label-width="160" input-align="right">
      <van-cell-group>
        <van-field
          v-model="item.dmfdmTyxydm"
          name="dmfdmTyxydm"
          label="统一信用代码："
          placeholder="请输入统一信用代码"
        />
        <van-field
          v-model="item.dmfdmSwqymc"
          name="dmfdmSwqymc"
          label="企业名称"
          placeholder="请输入企业名称"
        />
        <van-field
          v-model="item.latLng"
          name="form2"
          label="经纬度"
          placeholder="请输入经纬度"
          class="a"
          style="padding-left: 25px"
        >
          <template #right-icon>
            <img @click="toMap" src="../assets/detail/jwd.png" alt="" class="right-img" />
          </template>
        </van-field>
      </van-cell-group>

      <van-cell-group>
        <div class="cell-title">核对信息</div>
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmZcdz"
          label="经营地址："
          disabled
          placeholder="经营地址："
        />
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmZcdz"
          label="核查地址信息："
          disabled
          placeholder="请输入企业名称"
        />
        <van-radio-group
          class="textraea-radio"
          style="padding: 0 15px"
          v-model="item.rcBaseCheckState"
          direction="horizontal"
        >
          <van-radio name="0">是</van-radio>
          <van-radio name="1">否</van-radio>
          <van-radio name="2">查无下落</van-radio>
        </van-radio-group>
        <van-field
          v-show="item.rcBaseCheckState == '1' || item.rcBaseCheckState == '2'"
          label-width="100%"
          class="van-field-vertical textarea-txt"
          input-align="left"
          type="textarea"
          v-model="item.rcBaseCheckContent"
          autosize
          rows="3"
          placeholder="请输入核查地址"
        ></van-field>
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmSwjyfw"
          type="textarea"
          autosize
          disabled
          label="注册经营范围信息："
          placeholder="批发兼零售：家具、五金用品、室内装修材料（上述经营范围应在《市场名称登记证》有效的营业期限内经营）。"
        />
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmSwjyfw"
          type="textarea"
          disabled
          autosize
          label="核查经营范围信息："
          placeholder="批发兼零售：家具、五金用品、室内装修材料（上述经营范围应在《市场名称登记证》有效的营业期限内经营）。"
        />
        <van-radio-group
          class="textraea-radio"
          v-model="item.rcScopeCheckState"
          style="padding: 0 15px"
          direction="horizontal"
        >
          <van-radio name="0">是</van-radio>
          <van-radio name="1">否</van-radio>
          <van-radio name="2">未登记事项</van-radio>
        </van-radio-group>
        <van-field
          v-show="item.rcScopeCheckState == '1' || item.rcScopeCheckState == '2'"
          label-width="100%"
          class="van-field-vertical textarea-txt"
          input-align="left"
          type="textarea"
          v-model="item.rcScopeCheckContent"
          autosize
          placeholder="请核查经营范围"
        ></van-field>
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmZz"
          label="注册住所信息："
          disabled
          autosize
          placeholder="杭州市拱墅区XX街道XX社区XXX小区XX幢X单元XXXX室"
        />
        <van-field
          class="van-field-vertical"
          input-align="left"
          v-model="item.dmfdmZz"
          label="核查住所信息："
          autosize
          disabled
          placeholder="杭州市拱墅区XX街道XX社区XXX小区XX幢X单元XXXX室"
        />
        <van-radio-group
          class="textraea-radio"
          v-model="item.rcHomeCheckState"
          style="padding: 0 15px"
          direction="horizontal"
        >
          <van-radio name="0">是</van-radio>
          <van-radio name="1">否</van-radio>
          <van-radio name="2">未登记事项</van-radio>
        </van-radio-group>
        <van-field
          v-show="item.rcHomeCheckState == '1' || item.rcHomeCheckState == '2'"
          label-width="100%"
          disabled
          class="van-field-vertical textarea-txt"
          input-align="left"
          type="textarea"
          v-model="item.rcHomeCheckContent"
          autosize
          rows="3"
          placeholder="请核查住所信息"
        ></van-field>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { getDetail, toClaim } from '../api/home';

export default {
  data() {
    return {
      item: {
        rcUuid: '', //企业信息uuid
        dmfdmSwqymc: '', // 企业信息名称
        rcDmfdmUuid: '',
        dmfdmTyxydm: '', // 企业信息代码
        dmfdmZcdz: '', // 经营地址
        dmfdmTyxydm: '', //统一社会信用代码
        dmfdmZcdz: '', //企业地址
        dmfdmSwjyfw: '', //经营范围
        dmfdmZz: '', //住所信息
        rcBaseCheckState: '0', //基本信息核对情况 0-是 1-否 2-查无下落
        rcBaseCheckContent: '', //基本信息核对情况为1或2时填写
        rcScopeCheckState: '0', //经营范围核对情况 0-是 1-否 2-未登记事项
        rcScopeCheckContent: '',
        rcHomeCheckState: '0', // 住所信息核对情况 0-是 1-否 2-未登记事项
        rcHomeCheckContent: '',
        latLng: '',
      },
      queryItem: {},
    };
  },
  beforeRouteEnter(to, form, next) {
    next(vm => {
      Object.assign(vm.item, JSON.parse(vm.$route.query.item));
      vm.queryItem = vm.$route.query.item;
      // console.log(
      //   '🚀 ~ file: detail.vue ~ line 180 ~ beforeRouteEnter ~ vm.queryItem',
      //   vm.queryItem.latLng
      // );

      vm.getDetail();
    });
  },
  methods: {
    getDetail() {
      getDetail({ uuid: this.item.rcDmfdmUuid }).then(res => {
        Object.assign(this.item, res.data);
        // console.log('🚀 ~ file: detail.vue ~ line 194 ~ getLocation ~ res', res);
      });
    },
    toMap() {
      this.$router.push({
        path: '/map',
        query: {
          item: this.queryItem,
        },
      });
    },

    onSubmit(value) {
      // console.log(this.item);
      if (this.item.latLng.length == 0) {
        Toast.fail('请选择企业经纬度！');
        return false;
      }

      let posArr = this.item.latLng.split(',');
      let that = this;
      let data = {
        rcUuid: this.item.rcUuid,
        rcBaseCheckState: this.item.rcBaseCheckState,
        rcBaseCheckContent: this.item.rcBaseCheckContent,
        rcScopeCheckState: this.item.rcScopeCheckState,
        rcScopeCheckContent: this.item.rcScopeCheckContent,
        rcHomeCheckState: this.item.rcHomeCheckState,
        rcHomeCheckContent: this.item.rcHomeCheckContent,
        rcLongitude: posArr[0],
        rcLatitude: posArr[1],
      };
      toClaim(data)
        .then(res => {
          Toast({
            type: 'success',
            message: '操作成功',
            onClose: function () {
              that.$router.push('/enterprise-claim');
            },
          });
        })
        .catch(err => {
          Toast.fail('操作失败， 请重试');
        });
      // console.log(value);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .a {
  position: relative;
  padding-left: 10px;
}
::v-deep .a::before {
  display: block;
  content: '*';
  color: red;
  left: 15px;
  position: absolute;
  z-index: 3;
}
::v-deep .van-field--disabled .van-field__label {
  color: #666 !important;
}
.detail {
  ::v-deep .right-img {
    display: block;
    width: 0.4rem;
    height: 0.4rem;
  }
  ::v-deep .textraea-radio {
    justify-content: space-between;
    width: 100%;
  }
  ::v-deep .textarea-txt {
    .van-field__body {
      margin-top: 0.2rem;
      background: #fafafa;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      padding: 5px;
    }
  }
}
</style>
